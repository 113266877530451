.newNode{
	
    margin-top: 20px;
}

.cutNode svg{
	margin-right:10px;
}


.admin{
	width:100%;
	height:100%;
	background: rgb(23, 23, 23);
	position: absolute;
	display: flex;
	flex-direction: row;


}
.admin canvas{
	flex: 1;
}

.admin .tabs{

}
.admin .tabButtons{
	display: flex;
    justify-content: space-around;
    padding: 10px;

}

.admin .tabButtons button{
	background: none;
	border: none;
	color: white;
	flex: 1;
	padding: 10px;

}
.admin .tabButtons button:first-child{
	border-right: solid 1px white;
}


.admin .transformModeButtons{
   padding: 20px;
    display: flex;
    flex-direction: column;
    

}
.admin .transformModeButtons button{
	border-radius: 50%;
    border: solid 2px white;
    background: none;
    color: white;
    height: 50px;
    width: 50px;
	margin-bottom: 10px;
	box-sizing: border-box;
}