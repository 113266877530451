.home{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: rgb(11, 11, 14);
    display: flex;
}
.timelineContainer{
    z-index: 10;
    width:100vw;
    height:100vh;
    position: absolute;
    opacity: 1.0;
    top:0px;
    left: 0px;
    
   background: red;
overflow: hidden;
}
.timelineContainer.show{
    transform:scale(1.0);
    opacity: 1.0;
}

.logo{
    
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 400;
    position:absolute;
    left:7vw;
    top: 20px;
    z-index: 12;
    opacity: 0.5;
}
.logo.hide{
    opacity: 0.0;
}
.navContainer{
    bottom: 0px;
    left: 0px;
    position: absolute;
    z-index: 12;
    width:100%;
    transition:all 0.5s cubic-bezier(.72,.08,.1,.99);
    -webkit-transition:all 0.5s cubic-bezier(.72,.08,.1,.99);
    transform:translateY(0px);
}
.navContainer.hide{
    transform:translateY(300px);
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 640px){
    /* some CSS here */
    .timelineContainer{
        width:100%;
        position: absolute;
    }
    .timelineContainer.show{
        transform:translateX(0px)
    }
  }
  


