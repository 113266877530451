.close-button{
    position: absolute;
    top: 50px;
    color: white;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    right: 50px;
    outline: none;
    font-size:20px;
    margin: 0px;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    background: #3d3e40;
    width:50px;
    height:50px;
}
.close-button p{
    margin-top: 0px;
    font-size: 12px;
    margin-right: 10px;
    font-weight: 900;
}
.close-button svg{
    font-size: 18px;
}