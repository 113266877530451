.node-list{
	left: 0px;
	bottom: 0px;
    padding: 50px 50px;
    margin-bottom: 0px;
    height: 240px;
    width: 100%;
    transition: all 1s ease-out;
    overflow: scroll;
	max-width: 100vw;
	min-width: 100%;
    background:linear-gradient(0deg, rgb(37, 37, 39),rgb(37, 37, 39), rgb(37, 37, 39),transparent);
	box-sizing: border-box;
	position: absolute;
	opacity:1;
}
.node-list.vertical{
	left: 0px;
    top: 0px;
    padding: 140px 30px;
    margin-bottom: 0px;
    height: 100%;
    width: 100%;
    transition: all 1s ease-out;
    overflow: scroll;
    max-width:400px;
    min-width: 400px;
    background: none;
    box-sizing: border-box;
	position: absolute;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	background:linear-gradient(90deg, #252627, #252627, rgba(37, 38, 39, 0));
	opacity:1;
}
	/* Hide scrollbar for Chrome, Safari and Opera */
	.node-list.vertical::-webkit-scrollbar {
		display: none;
	  }
.node-list.hide{
	pointer-events: none;
	opacity:0;
}	
.node-list.hide .node-list-item{
	pointer-events:none;
}  

.node-list-item{
	position: absolute;
	pointer-events: all;
}
.node-list .nodeListDivider{
    border-top: dotted 1px rgba(255,255,255,0.1);
	width:90%;
	position: relative;
	left: 5%;
	top: -20px;
	display: none;
}