.firebase-film-upload .dropzone{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    font-weight: 600;
    cursor:pointer;
    background: rgba(255,255,255,0.1);
    flex-shrink: 0;
    outline: none;
    margin:0px;
    width: 100%;
    height: 200px;
}



    
.firebase-film-upload .upload-task{
    border-radius: 5px;
    margin:0px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 600;
    box-sizing: border-box;
    border:solid 1px white;
    flex-shrink: 0;
    outline: none;
}


.firebase-film-upload video{
    outline: none;
    max-width: 300px;

}
.filmPreview{
    position: relative;
    width: 100%;
}
.filmPreview button{
    position: absolute;
    top: 5px;
    right:5px;
    height: 33px;
    margin-bottom: 0px;
}
   