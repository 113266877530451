
.search-input .searchButton{
    position: absolute;
    display: flex;
color:white;
    font-size: 18px;
    border: none;
    height: 50px;
    width: 50px;
    display: flex;
    outline: none;
    cursor: pointer;
    background: rgb(61 62 64);
    right: 0px;
    top: 0px;
    /* padding: 8px 15px; */
    border-radius: 7px;
    font-weight: 900;
    line-height: 50px;
    align-items: center;
    justify-content: center;
}

.search-input .searchButtonDone{
    position: absolute;
    right: 7px;
    top: 7px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    border: none;
    padding: 8px 15px;
    border-radius: 50pc;
    outline: none;
    cursor: pointer;
    background: rgb(61 62 64);
    text-transform: uppercase;
    font-weight: 900;
    box-sizing: border-box;
}

.search-input .searchInputWrapper{
    position: relative;
    overflow: hidden;
    height: 50px;
    border-radius: 50px;
    background: rgb(61 62 64);
    border-radius: 50px;
}

.search-input input{
    opacity: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    background: none;
    border: none;
    /* border-bottom: solid 1px white; */
    color: white;
    outline: none;
    background: none;
    font-weight: 900;
    
    text-transform: uppercase;
}

.search-input{
    position: absolute;
    right: 50px;
    top:50px;
}

.search-input input::placeholder{
    color: rgba(255,255,255,0.2);
}