@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
/*
font-family: 'Oswald', sans-serif;
font-family: 'Abril Fatface', cursive;
font-family: 'Rajdhani', sans-serif;
font-family: 'Khand', sans-serif;
font-family: 'Yeseva One', cursive;
font-family: 'Knewave', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'Prata', serif;
font-family: 'Cantata One', serif;
font-family: 'Arapey', serif;
*/
body {
  position: absolute;
  width:100%;
  height:100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
    overscroll-behavior-x: none;
  
  
  overflow: hidden;
}
html{
  overflow: hidden;
  overscroll-behavior-x: none;
  height:100%;

}

code {
  font-family: Roboto;
}
img{
  
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.App {
  text-align: center;
  background: rgb(11, 11, 14);
  width: 100%;
    height: 100%;
}



.App-header {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
.App-Timeline{
    bottom:0;
    position: absolute;
}

.App-ForceLayout{
  bottom:0;
  top:0;
  right: 0;
  left: 0;
  position: absolute;
}
.home{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: rgb(11, 11, 14);
    display: flex;
}
.timelineContainer{
    z-index: 10;
    width:100vw;
    height:100vh;
    position: absolute;
    opacity: 1.0;
    top:0px;
    left: 0px;
    
   background: red;
overflow: hidden;
}
.timelineContainer.show{
    transform:scale(1.0);
    opacity: 1.0;
}

.logo{
    
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 400;
    position:absolute;
    left:7vw;
    top: 20px;
    z-index: 12;
    opacity: 0.5;
}
.logo.hide{
    opacity: 0.0;
}
.navContainer{
    bottom: 0px;
    left: 0px;
    position: absolute;
    z-index: 12;
    width:100%;
    transition:all 0.5s cubic-bezier(.72,.08,.1,.99);
    -webkit-transition:all 0.5s cubic-bezier(.72,.08,.1,.99);
    transform:translateY(0px);
}
.navContainer.hide{
    transform:translateY(300px);
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 640px){
    /* some CSS here */
    .timelineContainer{
        width:100%;
        position: absolute;
    }
    .timelineContainer.show{
        transform:translateX(0px)
    }
  }
  



.timeline{
    position: relative;
  width: 100vw;
  height: 100vh;
  color: white;
  overflow: hidden;
  top:0px;
  background:rgb(37, 37, 39);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  /*background: linear-gradient(0.25turn, #030a20, #2a0d2c);*/

  
}
.timeline .info{
    color: white;
    text-align: left;
    padding: 10px;
    width:200px;
    flex-shrink: 0;
    flex:1 1;
    padding: 30px 0px 0px 40px;
    
}

.timeline canvas{
    width: 100% !important;
    height: 100% !important;
    position: relative;
    flex: 1 1;
    overflow: hidden;
    outline: none;
}
.timeline h1, .timeline h2, .timeline p{
    margin: 0px;
}

.timeline h2{
    font-size: 16px;
    color: rgba(255,255,255,0.3);
    font-weight: 400;
}
.timeline h2 b{
    color: rgba(255,255,255,1.0);
    opacity: 1;
    font-weight: 900;
}
.timeline h1{
    margin-top: -5px;
    font-size: 60px;
    line-height: 0.8;
    font-weight: 900;
    padding: 10px 0px;
}
.timeline p{
    font-weight: 500;
    font-size: 14px;
    margin-top: -5px;
}


.introText{
    position: absolute;
    width: 100%;
    padding: 100px;
    box-sizing: border-box;
    height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: 0.5s all ease-out;

}
.introText h2{
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    margin-top: 50px;
    opacity: 0;
    min-height: 40px;
    letter-spacing: 5px;
    transition: 0.5s all ease-out;
}
.introText h2 .prefix{
    margin-right: 10px;
}
.introText p{
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px; 
    color: #ffffff;
    transition: 0.5s all ease-out;
    
}
.introText button{
    max-width: 100px;
    border-radius: 50px;
    border: solid 1px white;
    padding: 10px 20px;
    color: white;
    background: none;
    font-size: 12px;
    margin-top: 60px;
    pointer-events: all;
    outline: none;
    transition: 0.5s all ease-out;
}
.introText button:hover{
    background:white;
    color: black;
}
.introText.loading h2 {
    opacity: 1;
}
.introText.loading p ,.introText.hide p{
    opacity: 0;
}

.introText.hide{
    opacity:0.0;
    pointer-events: none;
}
.introText.hide button, .introText.loading button{
    pointer-events: none;
    opacity: 0;
}

.crumb-list{
    flex-shrink: 0;
}


.tooltip{
    background:rgba(37, 37, 39,0.9);
    width:150px;
    text-align: left;
    padding:20px;
    margin-left:15px;
    /*border-radius:10px;*/
    top:0px;
    position: relative;
    transform:translate3d(-50%,calc(-50px - 100%),0);
    pointer-events: none;
    /*box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.321);*/
}
.tooltip.invert{
    transform:translate3d(-50%,calc(+50px - 0%),0);
}
.tooltip h2{
    color: white !important;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
	max-width: 150px;
    margin: 0px;
    line-height: 1.1;
}
.tooltip .dateText{
    font-weight: 700;
    opacity: 1;
    font-size: 10px;
    margin-top: 10px;
    line-height: 1.1;
    border-radius: 50px;
    background: rgba(255,255,255,0.1);
    padding:4px  7px;
    display: inline-block;
    
}
.tooltip .colorBadge{
    width:7px;
    height:calc(100% - 40px);
    position: absolute;
    border-radius: 10px;
    top: 21px;

}
.actionText{
    font-size: 8px;
    font-weight: bold;
    color: white;
    position: relative;
    top: 0px;
    background:rgb(98, 197, 6);
    background:rgb(233, 5, 92);
    border-radius: 50px;
    padding:2px  7px;
margin-bottom: 4px;
    display: inline-block;
    box-sizing: border-box;
    transition: 0.5s all ease-out;
}
.tooltip-line{

    display: none;
    position: absolute;
    top: -100px;
    left: -95px;

}


.dimensionButton{
    position: absolute;
    right: 60px;
    bottom: 50px;
    color: #c6cace;
    border-radius: 50px;
    background: none;
    background: #3d3e40;
    border: none;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    transition:0.5s all ease-out;
    z-index: 1000;
}
.dimensionButton p{
    margin:0px;
    font-weight: 900;
    font-size: 12px;
}
.dimensionButton svg{
    margin-right:6px;
    font-size:12px;
}

.dimensionButton.hide{
    opacity:0.0;
    transform:scale(0.0);

}
.dimensionButton.top{
    left:50px;
    top:100px;
    bottom: auto;
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 700px){
    /* some CSS here */
    .timeline{ 
        flex-direction: column;
        align-items: flex-start;
    }
    .timeline .info{
        padding: 30px 0px 0px 40px;
        
    }
    .levelInfo{
        background: none;
    }
  }
  


.search-input .searchButton{
    position: absolute;
    display: flex;
color:white;
    font-size: 18px;
    border: none;
    height: 50px;
    width: 50px;
    display: flex;
    outline: none;
    cursor: pointer;
    background: rgb(61 62 64);
    right: 0px;
    top: 0px;
    /* padding: 8px 15px; */
    border-radius: 7px;
    font-weight: 900;
    line-height: 50px;
    align-items: center;
    justify-content: center;
}

.search-input .searchButtonDone{
    position: absolute;
    right: 7px;
    top: 7px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    border: none;
    padding: 8px 15px;
    border-radius: 50pc;
    outline: none;
    cursor: pointer;
    background: rgb(61 62 64);
    text-transform: uppercase;
    font-weight: 900;
    box-sizing: border-box;
}

.search-input .searchInputWrapper{
    position: relative;
    overflow: hidden;
    height: 50px;
    border-radius: 50px;
    background: rgb(61 62 64);
    border-radius: 50px;
}

.search-input input{
    opacity: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    background: none;
    border: none;
    /* border-bottom: solid 1px white; */
    color: white;
    outline: none;
    background: none;
    font-weight: 900;
    
    text-transform: uppercase;
}

.search-input{
    position: absolute;
    right: 50px;
    top:50px;
}

.search-input input::-webkit-input-placeholder{
    color: rgba(255,255,255,0.2);
}

.search-input input:-ms-input-placeholder{
    color: rgba(255,255,255,0.2);
}

.search-input input::-ms-input-placeholder{
    color: rgba(255,255,255,0.2);
}

.search-input input::placeholder{
    color: rgba(255,255,255,0.2);
}
.nodecard h2{
    color: #88898a !important;
    /* text-transform: uppercase; */
    font-weight: 400;
    font-size: 14px;
    max-width: 150px;
    margin: 0px;
    line-height: 1.1;
}
.nodecard p{
    font-weight: 400;
    opacity: 0.2;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 150px;
    line-height: 1.1;
    
}
.nodecard .category{
    font-weight: 700;
    opacity: 0.2;
    font-size: 12px;
    margin-top: 10px;
    width: 50px;
    line-height: 1.1;
    position: absolute;
    top: 10px;
    right: 0px;
    transform:translate3d(36px, 35px, 0px) rotate(90deg);
    
}
.nodecard .colorBadge{
    width: 7px;
    height: 7px;
    border-radius: 10px;
    position: absolute;
    top: 23px;
    right: -15px;

}
.nodecard{
    width: 190px;
    max-width: 190px;
    padding: 20px 0px;
    cursor: pointer;
    text-align: left;
    /*background: #2d2e2f;
    border-radius: 20px;*/
    margin: 0px 20px;
   /* border-bottom: dotted 1px rgba(255, 255, 255, 0.105);*/
    position: relative;
}
.nodecard .actionText.enlarge{
    font-size: 10px;
    margin-top: 10px;
    padding: 7px 12px;
    color: black;
}
.nodecard .esc{
    border: solid white 1.5px;
    border-radius: 4px;
    color: white;
    padding: 2px 3px;
    font-weight: 900;
    font-size: 6px;
    height: 8px;
    line-height: 8px;
    margin-top: 1px;
    /* position: relative; */
    /* display: block; */
    text-align: center;
	padding-left: 4px;
	margin-left:5px;
	
	position: absolute;
}
.nodecard svg{
	margin-left:8px;
}
.node-list{
	left: 0px;
	bottom: 0px;
    padding: 50px 50px;
    margin-bottom: 0px;
    height: 240px;
    width: 100%;
    transition: all 1s ease-out;
    overflow: scroll;
	max-width: 100vw;
	min-width: 100%;
    background:linear-gradient(0deg, rgb(37, 37, 39),rgb(37, 37, 39), rgb(37, 37, 39),transparent);
	box-sizing: border-box;
	position: absolute;
	opacity:1;
}
.node-list.vertical{
	left: 0px;
    top: 0px;
    padding: 140px 30px;
    margin-bottom: 0px;
    height: 100%;
    width: 100%;
    transition: all 1s ease-out;
    overflow: scroll;
    max-width:400px;
    min-width: 400px;
    background: none;
    box-sizing: border-box;
	position: absolute;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	background:linear-gradient(90deg, #252627, #252627, rgba(37, 38, 39, 0));
	opacity:1;
}
	/* Hide scrollbar for Chrome, Safari and Opera */
	.node-list.vertical::-webkit-scrollbar {
		display: none;
	  }
.node-list.hide{
	pointer-events: none;
	opacity:0;
}	
.node-list.hide .node-list-item{
	pointer-events:none;
}  

.node-list-item{
	position: absolute;
	pointer-events: all;
}
.node-list .nodeListDivider{
    border-top: dotted 1px rgba(255,255,255,0.1);
	width:90%;
	position: relative;
	left: 5%;
	top: -20px;
	display: none;
}
.close-button{
    position: absolute;
    top: 50px;
    color: white;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    right: 50px;
    outline: none;
    font-size:20px;
    margin: 0px;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    background: #3d3e40;
    width:50px;
    height:50px;
}
.close-button p{
    margin-top: 0px;
    font-size: 12px;
    margin-right: 10px;
    font-weight: 900;
}
.close-button svg{
    font-size: 18px;
}
.next-bar .button{
    
  
    color: #c6cace;
    border-radius: 50px;
    background: #3d3e40;
    border: none;
    padding: 5px 5px;
    height:30px;
    outline: none;
    font-size:20px;
    margin: 0px;
    cursor: pointer;
    flex-grow:0;
    pointer-events: all;
}
.next-bar .button:hover{
    background: white;
    color: black;
}
.next-bar .right-arrow{

}
.next-bar .left-arrow{
    
}
.next-bar .projectCount{
    font-size: 12px;
    margin: 0px 20px;
    font-weight: 900;
    
    text-align: right;
}
.close-button svg{
    font-size: 18px;
}

.next-bar{
    pointer-events: none;
    position: absolute;
    background: #3d3e40;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    /* padding: 10px 50px 20px 50px; */
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background: none; */
    left: 50%;
    bottom: 40px;
   
}


.logo{
    position: absolute;
    padding: 50px 50px;
    width: 100%;
	height: 170px;
	left: 0px;
    /* padding-left: 70px; */
    top: 0px;
    z-index: 1000;
    text-align: left;
    background: linear-gradient(-180deg, #252627, #252627, rgba(37, 38, 39, 0));
    max-width: 300px;
    color: white !important;
    opacity: 1.0;
    box-sizing: border-box;
    transition: 0.5s all ease-out;
    pointer-events: none;
}
.logo.hide{
    opacity:0.0;
}
.logo.island{
	background:none;
}
.logo h2{
    pointer-events: all;
    font-size: 12px;
    color: rgb(255 255 255);
    font-weight: 900;
    letter-spacing: 2px;
}
.logo p{
	pointer-events: all;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    opacity: 0.3;
    /* text-decoration: dashed; */
    /* text-transform: uppercase; */
    letter-spacing: 0.3px;;
	letter-spacing: 0.5px;
	
}
.island .logo-text{
	background:#3d3e40;
	transform: translate3d(0px,0px,0px);
}
.logo-text{
	
	transform: translate3d(-20px,0px,0px);
	transition: 0.5s all ease-out;
	padding: 10px 30px 10px 20px;
    background: none;
	border-radius: 50px;
	height: 50px;
	box-sizing: border-box;
    width: auto;
    position: absolute;
}
.newNode{
	
    margin-top: 20px;
}

.cutNode svg{
	margin-right:10px;
}


.admin{
	width:100%;
	height:100%;
	background: rgb(23, 23, 23);
	position: absolute;
	display: flex;
	flex-direction: row;


}
.admin canvas{
	flex: 1 1;
}

.admin .tabs{

}
.admin .tabButtons{
	display: flex;
    justify-content: space-around;
    padding: 10px;

}

.admin .tabButtons button{
	background: none;
	border: none;
	color: white;
	flex: 1 1;
	padding: 10px;

}
.admin .tabButtons button:first-child{
	border-right: solid 1px white;
}


.admin .transformModeButtons{
   padding: 20px;
    display: flex;
    flex-direction: column;
    

}
.admin .transformModeButtons button{
	border-radius: 50%;
    border: solid 2px white;
    background: none;
    color: white;
    height: 50px;
    width: 50px;
	margin-bottom: 10px;
	box-sizing: border-box;
}
.firebase-image{
    width:100%;
    height:100%;
    background-size: cover;
    transition: all 1s cubic-bezier(.72,.08,.1,.99);
}
img{
    width: 100%;
    height:100%;
    object-fit: cover;
    transition: all 1s cubic-bezier(.72,.08,.1,.99);
}
.node-form{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    height: calc(100% - 50px);
    box-sizing: border-box;
    overflow: scroll;
}
.node-form .section{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}
.node-form .section.title{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.node-form button{
    border: none;
    background: white;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 10px;

}
.node-form .imagePreview{
    max-height: 50px;
    max-width: 50px;
    border-radius: 5px;
    overflow: hidden;
}
.node-form .contentListItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.116);
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
}
.node-form .contentListItem p {
    flex: 1 1;
    text-align: left;
    margin: 10px;
    max-width: 300px;
}
.node-form .addContentButtons{
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px;
}
.node-form .addContentButtons p {
    flex: 1 1;
    text-align: left;
}

.node-form .addContentButtons button{
    background: none;
    color: white;
    height: 35px;
    outline: none;
} 
.node-form .contentListItem button{
    background: none;
    color: #ff0068;
    height: 35px;
    margin: 0px;
}


.firebase-input input{
    border: none;
    background: rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
	color:white;
	min-width: 300px;


}

.firebase-input textarea{
    border: none;
    background: rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    height:100px;
	color:white;
	min-width: 300px;

}
.firebase-input .color-input input{
    margin-right: 10px;
}

.firebase-input {
	color: white;
	position: relative;
	
}
.firebase-input .savedCheck svg{
	flex-shrink: 0;
	position: absolute;
	bottom: 15px;
	right:15px;
	font-size:12px;
	opacity: 0.5;
}
.firebase-input .savedCheck.small svg{
	flex-shrink: 0;
	position: absolute;
	bottom: 15px;
	right:5px;
	font-size:12px;
	opacity: 0.5;
}
.firebase-image-upload .dropzone{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    margin-bottom: 10px;
    font-weight: 600;
    cursor:pointer;
    background: rgba(255,255,255,0.1);
    flex-shrink: 0;
    outline: none;
    height: 60px;
    width:100px;
}
.firebase-image-upload .cover-image{
    
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
    margin: 4px;
    margin-bottom: 4px !important;
    height: 60px;
    width:100px;
    
}
.firebase-image-upload .image-grid{
    display: flex;
    overflow-x: scroll;
    max-width: 330px;
    flex-wrap: wrap;
}
.firebase-image-upload .deleteImageButton{
    position: absolute;
    bottom: 5px;
    right:5px;
    height: 33px;
    margin-bottom: 0px;
}
.firebase-image-upload .upload-task{
    border-radius: 5px;
    
    width:100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 600;
    margin: 5px;
    box-sizing: border-box;
    border:solid 1px white;
    flex-shrink: 0;
    outline: none;
}

.firebase-image-upload.single .dropzone{
    margin:0px;
    width: 100%;
    height: 200px;
}
.firebase-image-upload.single .upload-task{
    margin:0px;
    width: 100%;
    height: 200px;
}
.firebase-image-upload.single .cover-image{
    margin:0px;
    width: 100%;
    height: 200px;
}

.content-form{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    min-width: 300px;
}
.content-form .section{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}
.content-form .section.title{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.content-form button{
    border: none;
    background: white;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 10px;

}
.content-form .contentTypeBanner{
    font-size: 30px;
    display: flex;
    align-items: center;
}

.content-form .contentTypeBanner p{
    margin: 0px;
    margin-left: 20px;
}


.content-form .optionBar{
    display: flex;
    width:100%;
    justify-content: space-around;
    align-items: center;
}
.content-form .optionBar button{
    background: none;
    color: white;
    font-size: 20px;
}


.firebase-film-upload .dropzone{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    font-weight: 600;
    cursor:pointer;
    background: rgba(255,255,255,0.1);
    flex-shrink: 0;
    outline: none;
    margin:0px;
    width: 100%;
    height: 200px;
}



    
.firebase-film-upload .upload-task{
    border-radius: 5px;
    margin:0px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 600;
    box-sizing: border-box;
    border:solid 1px white;
    flex-shrink: 0;
    outline: none;
}


.firebase-film-upload video{
    outline: none;
    max-width: 300px;

}
.filmPreview{
    position: relative;
    width: 100%;
}
.filmPreview button{
    position: absolute;
    top: 5px;
    right:5px;
    height: 33px;
    margin-bottom: 0px;
}
   
.firebase-film{
    width:100%;
    height:100%;
    background-size: cover;
}
video{
    width: 100%;
    height:100%;
    object-fit: cover;
}
