@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
/*
font-family: 'Oswald', sans-serif;
font-family: 'Abril Fatface', cursive;
font-family: 'Rajdhani', sans-serif;
font-family: 'Khand', sans-serif;
font-family: 'Yeseva One', cursive;
font-family: 'Knewave', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'Prata', serif;
font-family: 'Cantata One', serif;
font-family: 'Arapey', serif;
*/
body {
  position: absolute;
  width:100%;
  height:100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
    overscroll-behavior-x: none;
  
  
  overflow: hidden;
}
html{
  overflow: hidden;
  overscroll-behavior-x: none;
  height:100%;

}

code {
  font-family: Roboto;
}
img{
  
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}