.timeline{
    position: relative;
  width: 100vw;
  height: 100vh;
  color: white;
  overflow: hidden;
  top:0px;
  background:rgb(37, 37, 39);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  /*background: linear-gradient(0.25turn, #030a20, #2a0d2c);*/

  
}
.timeline .info{
    color: white;
    text-align: left;
    padding: 10px;
    width:200px;
    flex-shrink: 0;
    flex:1;
    padding: 30px 0px 0px 40px;
    
}

.timeline canvas{
    width: 100% !important;
    height: 100% !important;
    position: relative;
    flex: 1;
    overflow: hidden;
    outline: none;
}
.timeline h1, .timeline h2, .timeline p{
    margin: 0px;
}

.timeline h2{
    font-size: 16px;
    color: rgba(255,255,255,0.3);
    font-weight: 400;
}
.timeline h2 b{
    color: rgba(255,255,255,1.0);
    opacity: 1;
    font-weight: 900;
}
.timeline h1{
    margin-top: -5px;
    font-size: 60px;
    line-height: 0.8;
    font-weight: 900;
    padding: 10px 0px;
}
.timeline p{
    font-weight: 500;
    font-size: 14px;
    margin-top: -5px;
}


.introText{
    position: absolute;
    width: 100%;
    padding: 100px;
    box-sizing: border-box;
    height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: 0.5s all ease-out;

}
.introText h2{
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    margin-top: 50px;
    opacity: 0;
    min-height: 40px;
    letter-spacing: 5px;
    transition: 0.5s all ease-out;
}
.introText h2 .prefix{
    margin-right: 10px;
}
.introText p{
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px; 
    color: #ffffff;
    transition: 0.5s all ease-out;
    
}
.introText button{
    max-width: 100px;
    border-radius: 50px;
    border: solid 1px white;
    padding: 10px 20px;
    color: white;
    background: none;
    font-size: 12px;
    margin-top: 60px;
    pointer-events: all;
    outline: none;
    transition: 0.5s all ease-out;
}
.introText button:hover{
    background:white;
    color: black;
}
.introText.loading h2 {
    opacity: 1;
}
.introText.loading p ,.introText.hide p{
    opacity: 0;
}

.introText.hide{
    opacity:0.0;
    pointer-events: none;
}
.introText.hide button, .introText.loading button{
    pointer-events: none;
    opacity: 0;
}

.crumb-list{
    flex-shrink: 0;
}


.tooltip{
    background:rgba(37, 37, 39,0.9);
    width:150px;
    text-align: left;
    padding:20px;
    margin-left:15px;
    /*border-radius:10px;*/
    top:0px;
    position: relative;
    transform:translate3d(-50%,calc(-50px - 100%),0);
    pointer-events: none;
    /*box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.321);*/
}
.tooltip.invert{
    transform:translate3d(-50%,calc(+50px - 0%),0);
}
.tooltip h2{
    color: white !important;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
	max-width: 150px;
    margin: 0px;
    line-height: 1.1;
}
.tooltip .dateText{
    font-weight: 700;
    opacity: 1;
    font-size: 10px;
    margin-top: 10px;
    line-height: 1.1;
    border-radius: 50px;
    background: rgba(255,255,255,0.1);
    padding:4px  7px;
    display: inline-block;
    
}
.tooltip .colorBadge{
    width:7px;
    height:calc(100% - 40px);
    position: absolute;
    border-radius: 10px;
    top: 21px;

}
.actionText{
    font-size: 8px;
    font-weight: bold;
    color: white;
    position: relative;
    top: 0px;
    background:rgb(98, 197, 6);
    background:rgb(233, 5, 92);
    border-radius: 50px;
    padding:2px  7px;
margin-bottom: 4px;
    display: inline-block;
    box-sizing: border-box;
    transition: 0.5s all ease-out;
}
.tooltip-line{

    display: none;
    position: absolute;
    top: -100px;
    left: -95px;

}


.dimensionButton{
    position: absolute;
    right: 60px;
    bottom: 50px;
    color: #c6cace;
    border-radius: 50px;
    background: none;
    background: #3d3e40;
    border: none;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    transition:0.5s all ease-out;
    z-index: 1000;
}
.dimensionButton p{
    margin:0px;
    font-weight: 900;
    font-size: 12px;
}
.dimensionButton svg{
    margin-right:6px;
    font-size:12px;
}

.dimensionButton.hide{
    opacity:0.0;
    transform:scale(0.0);

}
.dimensionButton.top{
    left:50px;
    top:100px;
    bottom: auto;
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 700px){
    /* some CSS here */
    .timeline{
        -webkit-flex-direction: column; 
        flex-direction: column;
        align-items: flex-start;
    }
    .timeline .info{
        padding: 30px 0px 0px 40px;
        
    }
    .levelInfo{
        background: none;
    }
  }
  
