.next-bar .button{
    
  
    color: #c6cace;
    border-radius: 50px;
    background: #3d3e40;
    border: none;
    padding: 5px 5px;
    height:30px;
    outline: none;
    font-size:20px;
    margin: 0px;
    cursor: pointer;
    flex-grow:0;
    pointer-events: all;
}
.next-bar .button:hover{
    background: white;
    color: black;
}
.next-bar .right-arrow{

}
.next-bar .left-arrow{
    
}
.next-bar .projectCount{
    font-size: 12px;
    margin: 0px 20px;
    font-weight: 900;
    
    text-align: right;
}
.close-button svg{
    font-size: 18px;
}

.next-bar{
    pointer-events: none;
    position: absolute;
    background: #3d3e40;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    /* padding: 10px 50px 20px 50px; */
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background: none; */
    left: 50%;
    bottom: 40px;
   
}