.firebase-image{
    width:100%;
    height:100%;
    background-size: cover;
    transition: all 1s cubic-bezier(.72,.08,.1,.99);
}
img{
    width: 100%;
    height:100%;
    object-fit: cover;
    transition: all 1s cubic-bezier(.72,.08,.1,.99);
}