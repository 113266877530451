.node-form{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    height: calc(100% - 50px);
    box-sizing: border-box;
    overflow: scroll;
}
.node-form .section{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}
.node-form .section.title{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.node-form button{
    border: none;
    background: white;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 10px;

}
.node-form .imagePreview{
    max-height: 50px;
    max-width: 50px;
    border-radius: 5px;
    overflow: hidden;
}
.node-form .contentListItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.116);
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
}
.node-form .contentListItem p {
    flex: 1;
    text-align: left;
    margin: 10px;
    max-width: 300px;
}
.node-form .addContentButtons{
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px;
}
.node-form .addContentButtons p {
    flex: 1;
    text-align: left;
}

.node-form .addContentButtons button{
    background: none;
    color: white;
    height: 35px;
    outline: none;
} 
.node-form .contentListItem button{
    background: none;
    color: #ff0068;
    height: 35px;
    margin: 0px;
}

