.nodecard h2{
    color: #88898a !important;
    /* text-transform: uppercase; */
    font-weight: 400;
    font-size: 14px;
    max-width: 150px;
    margin: 0px;
    line-height: 1.1;
}
.nodecard p{
    font-weight: 400;
    opacity: 0.2;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 150px;
    line-height: 1.1;
    
}
.nodecard .category{
    font-weight: 700;
    opacity: 0.2;
    font-size: 12px;
    margin-top: 10px;
    width: 50px;
    line-height: 1.1;
    position: absolute;
    top: 10px;
    right: 0px;
    transform:translate3d(36px, 35px, 0px) rotate(90deg);
    
}
.nodecard .colorBadge{
    width: 7px;
    height: 7px;
    border-radius: 10px;
    position: absolute;
    top: 23px;
    right: -15px;

}
.nodecard{
    width: 190px;
    max-width: 190px;
    padding: 20px 0px;
    cursor: pointer;
    text-align: left;
    /*background: #2d2e2f;
    border-radius: 20px;*/
    margin: 0px 20px;
   /* border-bottom: dotted 1px rgba(255, 255, 255, 0.105);*/
    position: relative;
}
.nodecard .actionText.enlarge{
    font-size: 10px;
    margin-top: 10px;
    padding: 7px 12px;
    color: black;
}
.nodecard .esc{
    border: solid white 1.5px;
    border-radius: 4px;
    color: white;
    padding: 2px 3px;
    font-weight: 900;
    font-size: 6px;
    height: 8px;
    line-height: 8px;
    margin-top: 1px;
    /* position: relative; */
    /* display: block; */
    text-align: center;
	padding-left: 4px;
	margin-left:5px;
	
	position: absolute;
}
.nodecard svg{
	margin-left:8px;
}