.content-form{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    min-width: 300px;
}
.content-form .section{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}
.content-form .section.title{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.content-form button{
    border: none;
    background: white;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 10px;

}
.content-form .contentTypeBanner{
    font-size: 30px;
    display: flex;
    align-items: center;
}

.content-form .contentTypeBanner p{
    margin: 0px;
    margin-left: 20px;
}


.content-form .optionBar{
    display: flex;
    width:100%;
    justify-content: space-around;
    align-items: center;
}
.content-form .optionBar button{
    background: none;
    color: white;
    font-size: 20px;
}

