.firebase-input input{
    border: none;
    background: rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
	color:white;
	min-width: 300px;


}

.firebase-input textarea{
    border: none;
    background: rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    height:100px;
	color:white;
	min-width: 300px;

}
.firebase-input .color-input input{
    margin-right: 10px;
}

.firebase-input {
	color: white;
	position: relative;
	
}
.firebase-input .savedCheck svg{
	flex-shrink: 0;
	position: absolute;
	bottom: 15px;
	right:15px;
	font-size:12px;
	opacity: 0.5;
}
.firebase-input .savedCheck.small svg{
	flex-shrink: 0;
	position: absolute;
	bottom: 15px;
	right:5px;
	font-size:12px;
	opacity: 0.5;
}