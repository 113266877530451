

.logo{
    position: absolute;
    padding: 50px 50px;
    width: 100%;
	height: 170px;
	left: 0px;
    /* padding-left: 70px; */
    top: 0px;
    z-index: 1000;
    text-align: left;
    background: linear-gradient(-180deg, #252627, #252627, rgba(37, 38, 39, 0));
    max-width: 300px;
    color: white !important;
    opacity: 1.0;
    box-sizing: border-box;
    transition: 0.5s all ease-out;
    pointer-events: none;
}
.logo.hide{
    opacity:0.0;
}
.logo.island{
	background:none;
}
.logo h2{
    pointer-events: all;
    font-size: 12px;
    color: rgb(255 255 255);
    font-weight: 900;
    letter-spacing: 2px;
}
.logo p{
	pointer-events: all;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    opacity: 0.3;
    /* text-decoration: dashed; */
    /* text-transform: uppercase; */
    letter-spacing: 0.3px;;
	letter-spacing: 0.5px;
	
}
.island .logo-text{
	background:#3d3e40;
	transform: translate3d(0px,0px,0px);
}
.logo-text{
	
	transform: translate3d(-20px,0px,0px);
	transition: 0.5s all ease-out;
	padding: 10px 30px 10px 20px;
    background: none;
	border-radius: 50px;
	height: 50px;
	box-sizing: border-box;
    width: auto;
    position: absolute;
}