.App {
  text-align: center;
  background: rgb(11, 11, 14);
  width: 100%;
    height: 100%;
}



.App-header {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
.App-Timeline{
    bottom:0;
    position: absolute;
}

.App-ForceLayout{
  bottom:0;
  top:0;
  right: 0;
  left: 0;
  position: absolute;
}