.firebase-image-upload .dropzone{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    margin-bottom: 10px;
    font-weight: 600;
    cursor:pointer;
    background: rgba(255,255,255,0.1);
    flex-shrink: 0;
    outline: none;
    height: 60px;
    width:100px;
}
.firebase-image-upload .cover-image{
    
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
    margin: 4px;
    margin-bottom: 4px !important;
    height: 60px;
    width:100px;
    
}
.firebase-image-upload .image-grid{
    display: flex;
    overflow-x: scroll;
    max-width: 330px;
    flex-wrap: wrap;
}
.firebase-image-upload .deleteImageButton{
    position: absolute;
    bottom: 5px;
    right:5px;
    height: 33px;
    margin-bottom: 0px;
}
.firebase-image-upload .upload-task{
    border-radius: 5px;
    
    width:100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 600;
    margin: 5px;
    box-sizing: border-box;
    border:solid 1px white;
    flex-shrink: 0;
    outline: none;
}

.firebase-image-upload.single .dropzone{
    margin:0px;
    width: 100%;
    height: 200px;
}
.firebase-image-upload.single .upload-task{
    margin:0px;
    width: 100%;
    height: 200px;
}
.firebase-image-upload.single .cover-image{
    margin:0px;
    width: 100%;
    height: 200px;
}
